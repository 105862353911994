import React from "react";
import {KeyRow} from "../KeyRow/KeyRow";

import './Keyboard.css'
import {KeyBoardType} from "../../types";

export interface KeyboardProps extends KeyBoardType {
    onClick:(a:string) => void,
    onDelete:() => void,
    onEnter:() => void
}

export const Keyboard:React.FC<KeyboardProps> = (props) => {
    const {
        firstRow,
        secondRow,
        thirdRow,
        onClick,
        onDelete,
        onEnter
    } = props;

    return (
        <div className="keyboard">
            <KeyRow keys={firstRow} onClick={onClick}/>
            <KeyRow keys={secondRow} onClick={onClick}/>
            <KeyRow keys={thirdRow} onClick={onClick} onDelete={onDelete} onEnter={onEnter}/>
        </div>
    );
}