import React from "react";
import {LetterDisplayType} from "../../types";
import {LetterDisplay} from "../LetterDisplay/LetterDisplay";

import './WordDisplay.css'

export type WordDisplayProps = {
    values:LetterDisplayType[][],
    updateStatus:(word: number, position: number, status: 'EXACT' | 'UNMATCHED' | 'EXCLUDED') => void
}

export const WordDisplay:React.FC<WordDisplayProps> = (props) => {
    const {values, updateStatus} = props;
    return (
        <div className="wordDisplay">
            {
                values.map(word => {
                    const renderedWord = word.map(letter => {
                        return <LetterDisplay {...letter} updateStatus={updateStatus}/>
                    })
                    return(
                        <div className="word">
                            {renderedWord}
                        </div>
                    )
                })
            }
        </div>
    )
}