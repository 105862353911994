import {PossibleWordsRequest, PossibleWordsResponse} from "../types";

const PATH = '/api/wordle-possible-words';

export const fetchPossibleWords = (request:PossibleWordsRequest):Promise<PossibleWordsResponse> => {
    let host = ''
    let headers = {
        'content-type': 'application/json'
    }

    if (process.env.NODE_ENV === 'development') {
        host = 'http://localhost:8080';
    }

    return fetch(
        `${host}${PATH}`,
    {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(request),
        })
        .then(response => {
            if(!response.ok) {
                throw new Error(response.statusText);
            }
            return response.json();
        });
}