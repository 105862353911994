import React from "react";

import './Key.css'
import {FiDelete} from "react-icons/fi";

export type DeleteKeyProps = {
    onDelete:()=>void
}

export const DeleteKey:React.FC<DeleteKeyProps> = (props) => {
    const onDelete = props.onDelete;

    return (
        <div className="key">
            <button className="key_button delete_button" onClick={onDelete}><FiDelete/></button>
        </div>
    );
}