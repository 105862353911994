import './WordleAsisstantPage.css'
import {LetterDisplayType} from "../types";
import {useRef, useState} from "react";
import {WordDisplay} from "../components/WordDisplay/WordDisplay";
import {Keyboard} from "../components/Keyboard/Keyboard";
import {PossibleWordsDisplay} from "../components/PossibleWordsDisplay/PossibleWordsDisplay";
import {wordsDisplayTransformer} from "../transformers/wordsDisplayTransformer";
import {fetchPossibleWords} from "../services/fetchPossibleWords";

import initialKeyboard from "../utils";
import {keyboardTransformer} from "../transformers/keyboardTransformer";

const WORD_SIZE = 5;
const WORD_COUNT = 6;

function initialWordDisplay() {
    const wordDisplay:LetterDisplayType[][] = [];
    for(let i=0; i<WORD_COUNT; i++) {
        let word:LetterDisplayType[] = []
        for(let j=0; j<WORD_SIZE; j++) {
            const letterDisplay:LetterDisplayType = {
                value: '',
                word: i,
                position: j,
                status: 'UNSET'
            }
            word.push(letterDisplay);
        }
        wordDisplay.push(word);
    }
    return wordDisplay;
}

export const WordleAssistantPage = () => {

    const [wordDisplay, setWordDisplay] = useState(initialWordDisplay());
    const [keyboard, setKeyboard] = useState(initialKeyboard());
    const [currentWord, setCurrentWord] = useState(0);
    const [currentPosition, setCurrentPosition] = useState(0);
    const [possibleWords , setPossibleWords] = useState([] as string[]);
    const [isLoading, setLoading] = useState(false);

    const keyboardRef = useRef<HTMLInputElement>(null);
    const wordDisplayRef = useRef<HTMLInputElement>(null);

    const keyClick = (key:string) => {
        if (currentPosition < WORD_SIZE) {
            wordDisplay[currentWord][currentPosition].value = key;
            setWordDisplay(wordDisplay);
            setCurrentPosition(currentPosition+1);
        }
    }

    const deleteClick = () => {
        if (currentPosition > 0) {
            wordDisplay[currentWord][currentPosition-1].value = '';
            wordDisplay[currentWord][currentPosition-1].status = 'UNSET';
            setWordDisplay(wordDisplay);
            setCurrentPosition(currentPosition-1);
        }
    }

    const updateStatus = (word: number, position: number, status: 'EXACT' | 'UNMATCHED' | 'EXCLUDED') => {
        wordDisplay[word][position].status = status;
        setWordDisplay([...wordDisplay]);
    }

    const onEnter = () => {
        if(wordDisplay[currentWord].every(letter => letter.status !== 'UNSET')) {
            setLoading(true);
            const request = wordsDisplayTransformer(wordDisplay);
            fetchPossibleWords(request)
                .then(response => response.words)
                .then(newWords => {
                    setPossibleWords(newWords);
                    setCurrentWord(currentWord+1)
                    setCurrentPosition(0);
                    setLoading(false);
                });
            setKeyboard(keyboardTransformer(request));
        }
    }

    const onWordClick = (word:string) => {
        wordDisplay[currentWord] =  wordDisplay[currentWord]
            .map((letter, index):LetterDisplayType => {
                const previousLetter = wordDisplay[currentWord-1][index];
                return {
                    ...letter,
                    value: word[index],
                    status: previousLetter.status === 'EXACT' ? 'EXACT' : 'UNSET'
                }
            });
        setWordDisplay([...wordDisplay]);
        setCurrentPosition(WORD_SIZE);
    }

    return (
        <div className="wordleassistant">
            <div className="wordleassistantContent">
                <div ref={wordDisplayRef} className="wordDisplayContainer">
                    <WordDisplay values={wordDisplay} updateStatus={updateStatus}/>
                </div>
                <PossibleWordsDisplay
                    words={possibleWords}
                    isLoading={isLoading}
                    keyboardHeight={keyboardRef.current?.offsetHeight || 0}
                    wordDisplayWidth={wordDisplayRef.current?.offsetWidth || 0}
                    onWordClick={onWordClick}
                />
                <div ref={keyboardRef} className="keyboardContainer">
                    <Keyboard {...keyboard} onClick={keyClick} onDelete={deleteClick} onEnter={onEnter}/>
                </div>
            </div>
        </div>
    )
}