import React from "react";
import './LetterDisplay.css'
import {LetterDisplayType, Status} from "../../types";
import {Tooltip} from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import {useScreenSize} from "../../hooks/useScreenSize";

export interface LetterDisplayProps extends LetterDisplayType {
    updateStatus:(word: number, position: number, status: 'EXACT' | 'UNMATCHED' | 'EXCLUDED') => void
}

function getStatusStyle(status: Status) {
    if (status === 'UNSET') {
        return 'unset';
    } else if (status === 'EXACT') {
        return 'exact';
    } else if (status === 'UNMATCHED') {
        return 'unmatched';
    } else {
        return 'excluded';
    }
}
export const LetterDisplay:React.FC<LetterDisplayProps> = (props) => {
    const {
        value,
        word,
        position,
        status,
        updateStatus } = props;

    const screenSize = useScreenSize();
    const id = `letter_display_${word}_${position}`;
    const statusStyle = getStatusStyle(status);

    const buttonClick = (status: 'EXACT' | 'UNMATCHED' | 'EXCLUDED') => {
        updateStatus(word, position, status);
    }

    const dimension = screenSize.width > 700 && screenSize.height > 800 ? '62px' : '58px'

    const style:React.CSSProperties = {
        height: dimension,
        width: dimension
    }

    return (
        <div className="letterDisplayWrapper">
            <div id={id}
                 className={`letterDisplay ${statusStyle} ${value ? 'fullDisplay': 'emptyDisplay'}`}
                 style={style}
            >
                <p className="letter">{value}</p>
            </div>
            {value ?
                <Tooltip anchorSelect={`#${id}`} openOnClick={true} clickable>
                    <div className="tooltip_button_wrapper">
                        <button className="exact_button" onClick={() => buttonClick('EXACT')}/>
                        <button className="unmatched_button" onClick={() => buttonClick('UNMATCHED')}/>
                        <button className="exclude_button" onClick={() => buttonClick('EXCLUDED')}/>
                    </div>
                </Tooltip>
            : null}
        </div>
    )
}