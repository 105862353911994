import React, {useRef} from "react";

import './PossibleWordsDisplay.css'
import {AiOutlineLoading} from "react-icons/ai";
import {useScreenSize} from "../../hooks/useScreenSize";

export type PossibleWordsDisplayProps = {
    words: string[],
    isLoading: boolean,
    keyboardHeight: number,
    wordDisplayWidth: number,
    onWordClick: (word: string)=> void
}

export const PossibleWordsDisplay:React.FC<PossibleWordsDisplayProps> = (props) => {
    const {
        words,
        isLoading,
        keyboardHeight,
        wordDisplayWidth,
        onWordClick
    }  = props;
    const wordsRef = useRef<HTMLInputElement>(null)
    const screenSize = useScreenSize();

    const style:React.CSSProperties = {
        height: 100,
        width: wordDisplayWidth
    }

    if(wordsRef.current) {
        const kh = keyboardHeight || 0;
        const offsetTop = wordsRef.current?.offsetTop ||  0;

        style.height = screenSize.height - kh - offsetTop - 35;
    }

    return (
        <div ref={wordsRef} className="possibleWords">
            {
                isLoading ?
                    <AiOutlineLoading />
                :
                    words.length > 0 ?
                        <div>
                            <div>Words Found: {words.length}</div>
                            <div className="wordContainer" style={style}>
                                <p>
                                    {
                                        words
                                            .map((word, index) =>
                                                <span className="possible-word" onClick={() => onWordClick(word)}>
                                                    <span className="word-hover">
                                                        {word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()}
                                                        {index < words.length-1? <span>,</span> : null}
                                                    </span>
                                                    {index < words.length-1? <span> </span> : null}
                                                </span>
                                            )
                                    }
                                </p>
                            </div>
                        </div>
                    : null
            }
        </div>
    );
}