import React from 'react';
import './App.css';
import {WordleAssistantPage} from "./pages/WordleAssistantPage";
import {Header} from "./header/Header";

function App() {
  return (
    <div className="App">
        <Header title="Wordle" url="https://www.nytimes.com/games/wordle/index.html"/>
        <WordleAssistantPage/>
    </div>
  );
}

export default App;
