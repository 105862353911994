import React from "react";

export type EnterKeyProps = {
    onEnter:() => void;
}

export const EnterKey:React.FC<EnterKeyProps> = (props) => {
    const onEnter = props.onEnter;

    return (
        <div className="key">
            <button className="key_button enter_button" onClick={onEnter}>ENTER</button>
        </div>
    );
}