import {KeyBoardType, PossibleWordsRequest, KeyType} from "../types";
import initialKeyboard from "../utils";

function mapKeyRow(key: KeyType, possibleWordsRequest: PossibleWordsRequest): KeyType {
    let match = possibleWordsRequest.excludedLetters
        .includes(key.value);
    if(match) {
        key.status = 'EXCLUDED';
    }

    match = possibleWordsRequest.unmatchedLetters
        .map(letter => letter.letter)
        .includes(key.value);

    if(match) {
        key.status = 'UNMATCHED';
    }

    match = possibleWordsRequest.exactLetters
        .map(letter => letter.letter)
        .includes(key.value);
    if(match) {
        key.status = 'EXACT'
    }

    return key;
}

export const keyboardTransformer = (possibleWordsRequest: PossibleWordsRequest):KeyBoardType => {
    const keyboard = initialKeyboard();

    return {
        firstRow: keyboard.firstRow
            .map(key => mapKeyRow(key, possibleWordsRequest)),
        secondRow: keyboard.secondRow
            .map(key => mapKeyRow(key, possibleWordsRequest)),
        thirdRow: keyboard.thirdRow
            .map(key => mapKeyRow(key, possibleWordsRequest))
    };
}