import React from "react";
import {Key} from "../Key/Key";

import './KeyRow.css'
import {DeleteKey} from "../Key/DeleteKey";
import {EnterKey} from "../Key/EnterKey";
import {KeyType} from "../../types";

export type KeyRowProps = {
    keys: KeyType[],
    onClick: (a:string) => void,
    onDelete?:() => void,
    onEnter?:() => void
}

export const KeyRow:React.FC<KeyRowProps> = (props) => {
    const {
        keys,
        onClick,
        onDelete,
        onEnter} = props;

    return (
        <div className="keyRow">
            {onEnter ? <EnterKey onEnter={onEnter}/> : null}
            {keys.map(key => <Key {...key} onClick={onClick}/>)}
            {onDelete ? <DeleteKey onDelete={onDelete}/> : null}
        </div>
    );
}