import {KeyBoardType, KeyType} from "./types";

function buildKey(value: string): KeyType {
    return {
        value: value,
        status: 'UNSET'
    }
}

function initialKeyboard(): KeyBoardType {
    const firstRow = ["Q","W","E","R","T","Y","U","I","O","P"];
    const secondRow = ["A","S","D","F","G","H","J","K","L"];
    const thirdRow = ["Z","X","C","V","B","N","M"];

    return {
        firstRow: firstRow.map(key => buildKey(key)),
        secondRow: secondRow.map(key => buildKey(key)),
        thirdRow: thirdRow.map(key => buildKey(key)),
    }
}

export default initialKeyboard;