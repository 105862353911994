import './Header.css'
import {useScreenSize} from "../hooks/useScreenSize";
import React from "react";
import {BiLinkExternal} from "react-icons/bi";

export type HeaderProps = {
    title: string,
    url: string
}

export const Header:React.FC<HeaderProps> = (props:HeaderProps) => {
    const { title, url } = props;
    const screenSize = useScreenSize();

    function screenSizeCondition() {
        return screenSize.width > 700 && screenSize.height > 800;
    }

    function fontSizeNum() {
        return screenSizeCondition() ? 32 : 28;
    }

    function fontSize() {
        return fontSizeNum() + 'px';
    }

    function halfFontSize() {
        return (fontSizeNum() / 2) + 'px';
    }

    const style:React.CSSProperties = {
        fontSize : fontSize(),
        marginTop: halfFontSize(),
        marginBottom: halfFontSize()
    }

    return (
        <div className="header">
            <header>
                <p className="header_text" style={style}>
                    The&nbsp;
                    <a href={url}
                       target="_blank"
                       rel="noreferrer"
                    >
                        {title}
                        <BiLinkExternal style={{fontSize: halfFontSize()}}/>
                    </a>
                    &nbsp;Assistant
                </p>
            </header>
        </div>
    )
}