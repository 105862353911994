import {LetterDisplayType, PossibleWordsRequest} from "../types";

function setUnmatched(request: PossibleWordsRequest, letter: LetterDisplayType) {
    let index = request.unmatchedLetters.findIndex(l => l.letter === letter.value);

    if(index === -1) {
        request.unmatchedLetters.push({
            letter: letter.value,
            positions: [letter.position]
        });
    } else {
        request.unmatchedLetters[index].positions.push(letter.position);
    }
}

function setExcludedUnmatchedLetters(request: PossibleWordsRequest, letter: LetterDisplayType) {
    let index = request.excludedUnmatchedLetters.findIndex(l => l.letter === letter.value);

    if(index === -1) {
        request.excludedUnmatchedLetters.push({
            letter: letter.value,
            positions: [letter.position]
        });
    } else {
        request.excludedUnmatchedLetters[index].positions.push(letter.position);
    }
}

function removeExtraUnmatched(request: PossibleWordsRequest, letter: LetterDisplayType, display:LetterDisplayType[][]) {
    if(request.unmatchedLetters.some(l => l.letter === letter.value)) {
        if(display[letter.word].filter(l => l.value === letter.value && l.status !== 'EXCLUDED').length === 1) {
            request.unmatchedLetters = request.unmatchedLetters
                .filter(l=> l.letter !== letter.value)
        }
    }
}

export const wordsDisplayTransformer = (display:LetterDisplayType[][]):PossibleWordsRequest => {
    let request:PossibleWordsRequest = {
        exactLetters: [],
        unmatchedLetters: [],
        excludedLetters: [],
        excludedUnmatchedLetters: []
    }

    display.flatMap(data => data)
        .filter(letter => letter.status !== 'UNSET')
        .forEach(letter => {
            if (letter.status === 'EXACT') {
                if(!request.exactLetters.some(l => l.letter === letter.value && l.position === letter.position)) {
                    request.exactLetters.push({
                        letter: letter.value,
                        position: letter.position
                    });
                }
                request.excludedLetters = request.excludedLetters
                    .filter(l=> l !== letter.value);
                removeExtraUnmatched(request, letter, display);
            } else if (letter.status === 'UNMATCHED') {
                setUnmatched(request, letter);
            } else if (letter.status === 'EXCLUDED') {
                if(request.exactLetters.some(l => l.letter === letter.value)) {
                    request.excludedLetters = request.excludedLetters
                        .filter(l=> l !== letter.value);
                    setExcludedUnmatchedLetters(request, letter);
                } else if(!request.excludedLetters.some(l => l === letter.value)) {
                    request.excludedLetters.push(letter.value)
                }
            }
        })

    return request;
}