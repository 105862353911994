import React from "react";

import './Key.css'
import {Status} from "../../types";
import {KeyType} from "../../types";

export interface KeyProps extends KeyType {
    onClick:(a:string) => void
};

function getStatusStyle(status:Status) {
    if(status === 'EXACT') {
        return 'exact_button';
    } else if (status === 'UNMATCHED') {
        return 'unmatched_button';
    } else if (status ==='EXCLUDED') {
        return 'excluded_button';
    } else {
        return 'unset_button';
    }
}

export const Key:React.FC<KeyProps> = (props) => {
    const {value, status, onClick} = props;

    const statusStyle = getStatusStyle(status);

    return (
        <div className="key">
            <button className={`key_button default_button ${statusStyle}`} onClick={() => onClick(value)}>{value}</button>
        </div>
    );
}